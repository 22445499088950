.admin-card {
    $time-animation: .5s;
    
    flex: 0 1 15%;
    background-color: $color-primary-one-1;
    padding: 3rem;
    margin-right: 11rem;
    transition: all $time-animation;
    cursor: pointer;

    //------------------ LARGE ------------------
    &--large {flex: 0 1 20%;}

    &--large &__avatar {
        width: 20rem;
        height: 20rem;
    }

    &--large &__caption{
        font-size: 2rem;
        margin: auto {top: 3rem;};
    }

    &--large &__content {width: 25rem;}

    &--large &__name {font-size: 2.5rem;}

    &--large &__nickname:link,
    &--large &__nickname:visited {
        font-size: 2rem;

        &:hover,
        &:active {transform: scale(1.1);}
    }

    &--large &__intro {
        font-size: 1.3rem;
        letter-spacing: 2.2px;
        line-height: 2.3rem;
    }

    &--large &__icon {
        width: 3rem;
        height: 3rem;
    }

    &--large:hover &__caption {
        transform: translate(-50%, -160%)!important;
    }
    //------------------ LARGE ------------------



    //------------------ YELLOW ------------------
    &--yellow {background-color: $color-primary-two-1;}

    &--yellow &__name, 
    &--yellow &__intro, 
    &--yellow &__nickname:link,
    &--yellow &__nickname:visited{
        color: $color-primary-one-1;
    }

    &--yellow &__name + hr {
        border: transparent {
            top: 1px solid $color-primary-one-1;
        };
    }

    &--yellow &__icon {fill: $color-primary-one-1;}
    //------------------ YELLOW ------------------


    //------------------ BLUE ------------------
    &--blue {background-color: $color-primary-one-1;}

    &--blue &__name, 
    &--blue &__intro, 
    &--blue &__nickname:link,
    &--blue &__nickname:visited{
        color: $color-primary-two-1;
    }

    &--blue &__name + hr {
        border: transparent {
            top: 1px solid $color-primary-two-1;
        };
    }

    &--blue &__icon {fill: $color-primary-two-1;}
    //------------------ BLUE ------------------

    &__avatar {
        width: 15rem;
        height: 15rem;
        margin: auto;
        overflow: hidden;
        position: relative;
        border-radius: 50%;

        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
            -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
            border-radius: none;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        transform: scale(1.2);
        backface-visibility: hidden;
        transition: all $time-animation;
    }

    &__caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: $color-white;
        font-size: 1.7rem;
        opacity: 0;
        transition: all $time-animation;
        backface-visibility: hidden;
    }

    &__content {
        width: 18rem;
        margin: auto {top: 2rem;};
    }

    &__name {
        font-size: 2rem;
        font-weight: 600;

        & + hr {
            margin: auto {top: 1rem;};
            width: 65%;
        }
    }

    &__intro {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 1.5rem;
        margin-top: .8rem
    }

    &__link {
        text-align: center;
        margin: auto {top: 2rem;};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1.2rem;
    }

    &__nickname {
        &,
        &:link,
        &:visited {
            font-size: 1.5rem;
            font-weight: 600;
            text-decoration: none;
            transition: all .3s;
        }

        &:hover,
        &:active {
            transform: scale(1.05);
        }
    }

    &:hover {
        transform: scale(1.3);
        box-shadow: $shadow-small-black;
    }

    &:hover &__caption {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    &:hover &__image {
        transform: scale(1);
        filter: blur(3px) brightness(80%);
    }

    @include respond(phone) {
        margin-right: 0;

        &:hover {
            transform: none;
            box-shadow: none;
        }
    
        &:hover &__caption {
            opacity: 0;
            transform: none;
        }
    
        &:hover &__image {
            transform: scale(1.2);
            filter: none;
        }

        &--large:hover &__caption {
            transform: none;
        }
    } 

}