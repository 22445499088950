.service {
    flex: 0 1 17%;
    height: 37rem;
    background-color: rgba($color-white, 0.6);
    border-radius: 2rem;
    
    box-shadow: $shadow-normal-black;
    cursor: pointer;
    transition: all .3s;

    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &__title {
        flex: 0 1 20%;
        @include respond(tab-port) {order: 2;}
    }

    &__content {
        position: relative;
        @include respond(tab-port) {order: 1;}
    }

    @include respond(big-desktop) {flex: 0 1 14%;}

    @include respond(desktop) {height: 33rem;}

    @include respond(tab-land) {height: 28rem;}

    @include respond(tab-port) {
        flex-direction: row;
        flex: 0 1 35%;
        padding: 1.5rem;
        height: 12rem;
        margin: 2rem 4rem;
    }

    @include respond(phone) {
        flex: 0 1 70%;
        padding: .5rem
    }


    &__img {
        fill: $color-primary-one-1;

        &--user {
            width: 17rem;
            height: 20rem;

            @include respond(desktop) {
                width: 15rem;
                height: 18rem;
            }

            @include respond(tab-land) {
                width: 13rem;
                height: 16rem;
            }

            @include respond(tab-port) {
                width: 6rem;
                height: 9rem;
            }
        }

        &--users{
            width: 17rem;
            height: 20rem;
            transform: translateX(.5rem);

            @include respond(desktop) {
                width: 15rem;
                height: 18rem;
            }


            @include respond(tab-land) {
                width: 13rem;
                height: 16rem;
            }

            @include respond(tab-port) {
                width: 6rem;
                height: 9rem;
            }
        }

        &--search {
            width: 11rem;
            height: 11rem;
            stroke: $color-white;
            stroke-width: 1px;
            position: absolute;
            bottom: 1rem;
            left: .5rem;

            @include respond(desktop) {
                width: 9rem;
                height: 9rem;
            }


            @include respond(tab-land) {
                width: 8rem;
                height: 8rem;
            }

            @include respond(tab-port) {
                width: 4rem;
                height: 4rem;
                bottom: 1.5rem;
                left: .3rem;
            }
        }

        &--user-tie {
            width: 17rem;
            height: 20rem;
            transform: translateY(.7rem);

            @include respond(desktop) {
                width: 15rem;
                height: 18rem;
            }


            @include respond(tab-land) {
                width: 13rem;
                height: 16rem;
            }

            @include respond(tab-port) {
                width: 6rem;
                height: 9rem;
            }
        }

        &--cogs {
            width: 12rem;
            height: 12rem;
            stroke: $color-white;
            stroke-width: .8px;
            position: absolute;
            bottom: -.7rem;
            right: -1rem;

            @include respond(desktop) {
                width: 10rem;
                height: 10rem;
            }

            @include respond(desktop) {
                width: 9rem;
                height: 9rem;
            }

            @include respond(tab-port) {
                width: 5rem;
                height: 5rem;
                bottom: 0rem;
            }
        }

        &--book {
            width: 14rem;
            height: 18rem;
            transform: translateY(1rem);

            @include respond(desktop) {
                width: 12rem;
                height: 16rem;
            }


            @include respond(tab-land) {
                width: 11rem;
                height: 15rem;
            }

            @include respond(tab-port) {
                width: 5rem;
                height: 9rem;
                transform: translateY(0rem);
            }
        }

        &--pencil {
            width: 10rem;
            height: 10rem;
            stroke: $color-white;
            stroke-width: 1.2px;
            position: absolute;
            bottom: -1.5rem;
            right: -1.2rem;

            @include respond(desktop) {
                width: 9rem;
                height: 9rem;
            }

            @include respond(desktop) {
                width: 8rem;
                height: 8rem;
            }

            @include respond(tab-port) {
                width: 4rem;
                height: 4rem;
                bottom: 1.5rem;
                right: -.7rem;
            }
        }
    }


    &:hover {
        box-shadow: $shadow-xlarge-black;
        transform: translateY(-.5rem) scale(1.05);
    }
}