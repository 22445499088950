.community-main {
    margin-top: 85rem;

    @include respond(big-desktop) {margin-top: 120rem;}

    @include respond(desktop) {margin-top: 100rem;}

    @include respond(tab-land) {margin-top: 80rem;}

    @include respond(tab-port) {margin-top: 100rem;}

    @include respond(phone) {margin-top: 70rem;}
}

.section-presentation {
    padding: 5rem 17rem;

    @include respond(desktop) {padding: 5rem 10rem;}

    @include respond(tab-land) {padding: 5rem;}
}

.section-form {

    &__form {
        width: 55%;
        padding: 5rem{right: 30rem};
        border-radius: 3rem;
        background-color: $color-background-5;
        margin: auto;
        border: 1px solid $color-primary-one-1;
        position: relative;
        box-shadow: $shadow-large-black;

        @include respond(desktop) {
            padding: 5rem{right: 25rem};
        }

        @include respond(tab-land) {padding: 5rem{right: 1rem};}

        @include respond(tab-port) {
            width: 80%;
            padding: 2rem {top:5rem};
        }

        @include respond(phone) {
            width: 95%;
            padding: 2rem {top: 5rem;};
        }

    }

    &__figure {
        position: absolute;
        top: 7rem;
        right: -10rem;

        @include respond(desktop) {
            top: 7rem;
            right: -13rem;
        }

        @include respond(tab-land) {display: none;}
    }

    &__img{
        width: 35rem;
        height: 55rem;
        box-shadow: $shadow-normal-black;
    }
    
}