.about-main {
    margin-top: 90rem;

    @include respond(big-desktop) {margin-top: 120rem;}

    @include respond(desktop) {margin-top: 100rem;}

    @include respond(tab-land) {margin-top: 85rem;}

    @include respond(tab-port) {margin-top: 100rem;}

    @include respond(phone) {margin-top: 75rem;}
}

.section-team {
    width: 100%;
    background-color: $color-primary-two-1;
    position: relative;
    padding: 12rem 17rem;

    @include respond(desktop) {padding: 12rem 5rem;}

    @include respond(tab-port) {padding: 12rem 0rem;}


    &__list {
        position: relative;
        z-index: 100;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        & > * {
            margin: 7rem 8rem;
            @include respond(desktop) {margin: 7rem 4rem;}

            @include respond(tab-port) {margin: 7rem 2rem;}
            @include respond(phone) {margin: 2rem 2rem;}
        }
    }
    
    .button-group {
        position: relative;
        z-index: 100;
    }

    h1 {
        position: relative;
        z-index: 100;
    }

    &__background {
        position: absolute;
        left: 0;
        width: 100%;
        height: 40rem;
        z-index: 1;
        
        background-repeat: no-repeat;
        background-size: cover;

        &--1 {
            background-image: url(../../Assets/Background/backgroundN6-1.png);
            top: -20rem;

            @include respond(big-desktop) {
                top: -27rem;
            }

            @include respond(tab-land) 
            {background-image: url(../../Assets/Background/backgroundN6-1-small.png);}

            @include respond(tab-port) 
            {background-image: url(../../Assets/Background/backgroundN6-1-xsmall.png);}
        }

        &--2 {
            background-image: url(../../Assets/Background/backgroundN6-2.png);
            bottom: -10rem;

            @include respond(big-desktop)
            {
                height: 50rem;
            }
            @include respond(tab-land) 
            {background-image: url(../../Assets/Background/backgroundN6-2-small.png);}

            @include respond(tab-port) 
            {background-image: url(../../Assets/Background/backgroundN6-2-xsmall.png);}
        }
    }
}

.section-mission{
    margin-bottom: 20rem;

    @include respond(big-desktop)
    {
        margin-bottom: 35rem;
    }

    @include respond(desktop)
    {
        margin-bottom: 30rem;
    }

    @include respond(tab-land)
    {
        margin-bottom: 25rem;
    }

    @include respond(tab-port)
    {
        margin-bottom: 20rem;
    }

    @include respond(phone)
    {
        margin-bottom: 15rem;
    }
}