.header {
    position: absolute;
    
    top: -15rem;
    left: 0;
    background-repeat: no-repeat;
    background-size:cover;

    height: 120rem;
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10rem;

    &--1 {
        background-image: url(../../Assets/Background/backgroundN2.svg);

        @include respond(tab-port) {
            background-image: url(../../Assets/Background/backgroundN2-small.svg);
        }
    }

   &--2 {
        background-image: url(../../Assets/Background/backgroundN4.svg);
        
        @include respond(tab-port) {
            background-image: url(../../Assets/Background/backgroundN4-small.svg);
        }
    }

   &--3 {
        background-image: url(../../Assets/Background/backgroundN5.svg);
        
        @include respond(tab-port) {
            background-image: url(../../Assets/Background/backgroundN5-small.svg);
        }
    }

    @include respond(tab-land) {
        top: -20rem;
        justify-content: center;
        height: 110rem;
    }

    @include respond(tab-port) {
        padding-top: 0rem;
        height: 125rem;
        top: -15rem;
    }

    @include respond(phone) {height: 105rem;}

    @include respond(big-desktop) {
        height: 150rem;
        top: -20rem;
    }

    @include respond(ultra-desktop) {
        height: 150rem;
        top: -20rem;
    }

    &__illustration {
        flex: 0 1 40%;
        transform: translateY(3.5rem);

        @include respond(tab-land) {
            transform: translateY(4.5rem);
        }

        @include respond(tab-port) {display: none;}
    }

    &__img {
        width: 55rem;
        height: 60rem;
        transform: translateY(2.5rem);

        @include respond(desktop) {
            transform: translateY(3.5rem);
        }

        @include respond(big-desktop) {
            width: 70rem;
            height: 75rem;
            transform: translateY(4.5rem);
        }

        @include respond(tab-land) {
            width: 47rem;
            height: 52rem;
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
    }

    &--1 &__content {
        height: 62rem;
        transform: translateY(-2rem);

        @include respond(desktop) {
            flex: 0 1 60%;
            padding-right: 8rem;
        }

        @include respond(big-desktop) {
            height: 75rem;
        }

        @include respond(tab-land) {
            height: 50rem;
            transform: translateY(2rem);
        }
        
        @include respond(tab-port) {
            padding-right: 0rem;
            flex: 0 1 100%;
            transform: translateY(0rem);
            height: 70rem;
        }

        @include respond(phone) {height: 50rem;}
    }

    &--2 &__content {
        height: 55rem;
        margin-top: 7rem;

        @include respond(big-desktop) {
            height: 65rem;
            margin-top: 20rem;
        }

        @include respond(desktop) {
            height: 65rem;
            margin-top: 20rem;
        }

        @include respond(phone) {
            height: 55rem;
            margin-top: 10rem;
        }

    }

    &--3 &__content {
        height: 55rem;
        margin-top: 1rem;

        @include respond(phone) {
            margin-top: 3rem;
        }
    }

    &__logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: translateY(1rem);

        @include respond(desktop) {transform: translateY(4rem);}

        @include respond(tab-land) {transform: translateY(2rem);}

        @include respond(tab-port) {
            margin: auto;
            transform: translate(-1rem,4rem);
        }
    }

    &__figure {
        width: 14rem;
        height: 14rem;

        @include respond(desktop) {
            width: 12rem;
            height: 12rem;
        }

        @include respond(tab-land) {
            width: 10rem;
            height: 10rem;
        }

        @include respond(tab-port) {
            width: 14rem;
            height: 14rem;
        }

        @include respond(phone) {
            width: 10rem;
            height: 10rem;
        }
    }

    &__caption {
        color: $color-white;
        font-size: 4.7rem;
        font-weight: 600;

        @include respond(desktop) {font-size: 4.2rem;}

        @include respond(tab-land) {font-size: 4rem;}

        @include respond(tab-port) {font-size: 4.7rem;}

        @include respond(phone) {font-size: 4rem;}
    }

    &__title {
        font-size: 6rem;
        font-weight: 500;
        color: $color-white;
        letter-spacing: 5px;
        line-height: 9rem;
        text-transform: uppercase;
        text-align: right;
        

        @include respond(desktop) {
            font-size: 4.8rem;
            line-height: 7rem;
        }

        @include respond(big-desktop) {
            font-size: 7.5rem;
            line-height: 13rem;
        }

        @include respond(tab-land) {
            font-size: 3.5rem;
            line-height: 6rem;
        }

        @include respond(tab-port) {
            text-align: center;
            margin: auto;
            line-height: 8rem;
            font-size: 3.8rem;
        }

        @include respond(phone) {
            line-height: 6rem;
            font-size: 2.3rem;
            letter-spacing: 3px;
        }

        &--right{
            position: relative;
            z-index: 1;

            &::after{
                content: "";
                position: absolute;
                z-index: -1;
                bottom: 0;
                right: 0;
                height: 40%;
                width: 90%;
                background-color: $color-secondary-two-1;

                @include respond(desktop) {width: 80%;}

                @include respond(tab-port) {width: 90%;}
            }
        }

        &--left {
            position: relative;
            z-index: 1;

            &::after{
                content: "";
                position: absolute;
                z-index: -1;
                bottom: 0;
                left: 0;
                height: 40%;
                width: 80%;
                background-color: $color-secondary-two-1;

                @include respond(desktop) {width: 70%;}

                @include respond(tab-port) {width: 80%;}
            }
        }
    } 
    
    &__title + button {
        margin-top: 3rem;

        @include respond(desktop) {margin-top: 0rem;}

        @include respond(tab-port) {margin: auto;}
    }
}