.popup{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color-black, 0.5);

  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
 
  &__box{
    width: 90vw;
    border-radius: 3rem;
    background: rgba($color: $color-background-3 , $alpha: 1);
  }

  &__close{
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
  }

  &__text
  {
    margin-top: 2vh;
    background: $color-white;
    border: 2px solid $color-secondary-one-2;
    border-radius: 2rem;
    padding: 1.5vh;
    box-shadow: $shadow-xsmall-black;
  }
}