.faq-item {
    background-color: $color-white;
    box-shadow: $shadow-small-black;
    border-radius: 2rem;
    border: 0.2rem dashed $color-primary-one-1;
        
    margin: auto;
    padding: 3rem;
    width: 70%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    transition: all .2s;

    @include respond(phone) {width: 90%;}

    &:hover {transform: scale(1.05);}

    &__checkbox {display: none;}

    &__checkbox:checked + &__button &__icon {
        transform: rotate(90deg);
    }

    &__checkbox:checked ~ &__description {display: block;}

    h3 {flex: 0 2 80%}

    &__button {
        flex: 0 1 15%;
        height: 5rem;
    }

    &__icon {
        display: none;
        fill: $color-primary-one-1;
        width: 100%;
        height: 100%;
        transition: all .2s;
        cursor: pointer;

        @include respond(tab-port) {display: block;}

        &:hover {transform: scale(1.1);}

        &:active {
            -webkit-tap-highlight-color: transparent;
        }
    }

    &__title {
        font-size: $normal-title-font-size;
        font-weight: 500;
        letter-spacing: 1.5px;
        
        @include respond(desktop) {
            font-size: $small-title-font-size;
        }
        
        @include respond(tab-land) {
            font-size: $xsmall-title-font-size;
        }
    }

    &__description{
        line-height: 4rem;
        letter-spacing: 2px;
        font-size: $default-font-size;
        transition: display .2s;
        margin-top: 1rem;

        @include respond(tab-port) {display: none;}
    }
}