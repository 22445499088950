.section-registration{
    width: 50%;
    @include absCenter;

    padding: 10rem 10rem;
    background-color: $color-background-5;
    border-radius: 5rem;
    box-shadow: $shadow-large-black;
    border: 1px solid $color-primary-one-1;

    @include respond(tab-land) {padding: 5rem;}

    @include respond(tab-port) {width: 75%;}

    @include respond(phone) {
        padding: 3rem;
        width: 90%;
    }
}