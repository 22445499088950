.community-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--reverse {
        flex-direction: row-reverse;
    }


    &--reverse &__title, &--reverse &__body {
        text-align: left;

        @include respond(tab-port) {
            text-align: center;
        }
    }

    @include respond(tab-port) {
        flex-direction: column;
    }

    &__figure {
        width: 55rem;
        height: 55rem;

        @include respond(desktop) {
            width: 47rem;
            height: 47rem;
        }

        @include respond(tab-land) {
            width: 40rem;
            height: 40rem;
        }

        @include respond(tab-port) {
            margin-bottom: 5rem;
        }

        @include respond(phone) {
            width: 30rem;
            height: 30rem;
        }
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
        opacity: 0.8;
    }

    &__content {
        flex: 0 1 55%;
        
        @include respond(desktop) {flex: 0 1 50%;}

        @include respond(big-desktop) {flex: 0 1 60%;}
    }

    &__title {
        font-size: 4rem;
        letter-spacing: 2px;
        text-align: right;

        @include respond(big-desktop) {
            font-size: 6rem;
        }

        @include respond(tab-land) {
            font-size: 3rem;
        }
    }

    &__body {
        font-size: 1.8rem;
        line-height: 4rem;
        letter-spacing: 2px;
        font-weight: 400;
        margin-top: 3rem;
        text-align: right;

        @include respond(desktop) {
            font-size: 1.5rem;
            line-height: 3.2rem;
        }

        @include respond(big-desktop) {
            font-size: 2.5rem;
            line-height: 5rem;
        }
    }



    @include respond(tab-port) {
        &__title, &__body {
            text-align: center;
        }
    }
}