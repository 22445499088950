.footer {
    $height: 110rem;
    width: 100%;

    background-image: url(../../Assets/Background/backgroundN3.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: $height;
    padding-top: calc(#{$height} - 85rem);

    @include respond(tab-port) {
        background-image: url(../../Assets/Background/backgroundN3-small.svg);
        height: 140rem;
    }

    @include respond(big-desktop) {
        padding-top: calc(#{$height} - 80rem);
        height: 130rem;
    }

    &__logo-box {
        height: 20rem;
        width: 20rem;
        margin: auto{top: calc(#{$height} - 91rem);};

        @include respond(big-desktop) {
            margin: auto{top: calc(#{$height} - 80rem);};
        }

        @include respond(tab-port) {
            height: 18rem;
            width: 18rem;
        }
    }

    &__logo {
        height: 100%;
        width: 100%;
    }

    &__content {
        display: flex;
        margin-top: 2rem;
        background-color: $color-primary-one-1;

        @include respond(tab-port) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &__navigation {
        flex: 0 1 50%;
        padding: 0 5rem;
    }

    &__title {
        color: $color-primary-two-1;
        font-size: 2.7rem;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin-bottom: 2rem;
        text-transform: uppercase;

        @include respond(tab-port) {font-size: 2.3rem;}
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding-top: 2rem;
        border-top: 1px solid $color-primary-two-1;
        flex-wrap: wrap;

        @include respond(desktop) {padding-top: 1rem;}

        @include respond(phone) {
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
        }
    }

    &__social {
        display: flex;
        justify-content: space-around;
        padding: 0 15rem;

        @include respond(desktop) {padding: 0 7rem;}

        @include respond(tab-land) {padding: 0rem;}

        @include respond(phone) {flex-wrap: wrap;}

    }

    &__icon-box {
        border-radius: 50%;
        border: 1px solid $color-primary-two-1;
        padding: 1.8rem;
        transition: all .2s;

        &:hover,
        &:active {
            background-color: $color-primary-two-1;
            box-shadow: 0 1rem 2rem rgba($color-black, .4);
            transform: scale(1.3);
        }

        @include respond(phone) {margin: .5rem;}
    }

    &__icon-box:hover &__icon {
        fill: $color-primary-one-1;
    }

    &__icon-box:active &__icon {
        fill: $color-primary-one-1;
    }

    &__icon {
        fill: $color-primary-two-1;
        padding: 0;
        margin: 0 1.5px;
        width: 3.2rem;
        height: 3.2rem;
        transition: all .2s;

        @include respond(tab-port) {
            width: 3rem;
            height: 3rem;
        }
    }

    &__copyright {
        flex: 0 1 50%;

        @include respond(tab-port) {margin-top: 4rem;}

        @include respond(phone) {
            padding: 0 2rem;
            margin-bottom: 2rem;
        }
        
    } 

    &__text {
        font-size: 1.4rem;
        letter-spacing: 1.5px;
        line-height: 3rem;
        font-weight: 300;
        color: $color-primary-two-1;
        width: 70%;
        margin: auto;
        border-top: 1px solid $color-primary-two-1;
        padding-top: 2rem;

        @include respond(desktop) {
            padding-top: 1rem;
            font-size: 1.2rem;
        }

        @include respond(phone) {
            font-size: 1rem;
            width: 100%;
        }
    }

}