.arrow {
    position: relative;

    @include respond(tab-port) {
        margin-top: 3rem;
    }

    @include respond(phone) {
        margin: 4rem 0rem;
    }

    $line-width: .2rem;
    &__line {
        margin: auto;
        height: 25rem;
        width: $line-width;
        background-image: linear-gradient($color-primary-one-1 33%, 
        rgba(255,255,255,0) 0%);
        background-position: right;
        background-size: $line-width 4rem;
        background-repeat: repeat-y;

        @include respond(tab-land) {
            height: 20rem;
        }

        @include respond(tab-port) {
            height: 15rem;
            background-size: $line-width 3rem;
            margin-top: 3rem;
        }

    }

    &__title {
        padding: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 2.3rem;
        color: $color-primary-one-1;
        background-color: $color-background-3;
        transform: translate(-6rem, -3rem);

        @include respond(tab-land) {
            transform: translate(-6rem, -4.5rem);
        }

        @include respond(tab-port) {
            font-size: 2rem;
            padding: .7rem;
            transform: translate(-4rem, -3rem);
        }
    }
}