// --------------------- MEDIA QUERY MANAGER ----------------
@mixin respond($breakpoint) {

    @if $breakpoint == phone {
        @media only screen and (max-width: $phone) { @content };
    }

    @if $breakpoint == tab-port {
        @media only screen and (max-width: $tab-port) { @content };
    }

    @if $breakpoint == tab-land {
        @media only screen and (max-width: $tab-land) { @content };
    }

    @if $breakpoint == desktop {
        @media only screen and (max-width: $desktop) { @content };
    }

    @if $breakpoint == big-desktop {
        @media only screen and (min-width: $big-deskstop) { @content }; 
    }

    @if $breakpoint == ultra-deskstop {
         @media only screen and (min-width: $ultra-deskstop) { @content }; 
    }
}
// --------------------- MEDIA QUERY MANAGER ----------------


// --------------------- BOX SHADOW MIXIN ----------------
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}
// --------------------- BOX SHADOW MIXIN ----------------


// --------------------- CENTER WITH POSITION ----------------
@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
// --------------------- CENTER WITH POSITION ----------------