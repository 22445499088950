.home-main {
    margin-top: 100rem;

    @include respond(big-desktop) {margin-top: 130rem;}

    @include respond(desktop) {margin-top: 105rem;}

    @include respond(tab-land) {margin-top: 85rem;}

    @include respond(tab-port) {margin-top: 105rem;}

    @include respond(phone) {margin-top: 85rem;}

}

.section-service {
    width: 100%;
    margin: auto;
    
    padding: 0 5rem;

    @include respond(desktop) {
        margin-top: 0rem;
        padding: 0rem;
    }

    @include respond(phone) {
        margin-top: 0rem;
    }

    &__group {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @include respond(tab-port) {
            flex-wrap: wrap;
            transform: translateY(-5rem);
        }
    }
}

.section-about-us {
    width: 100%;

    &__scroll{position: relative;}

    &__background {
        position: absolute;
        width: 100%;
        height: 40rem;
        top: 8rem;
        left: 0;
        z-index: -1;
        background-color: $color-primary-one-1;
        box-shadow: $shadow-normal-black;
    }
    
    &__card-group {
        height: 55rem;
        overflow-x: scroll;
        padding-left: 5rem;
        @include respond(phone) {
            padding-left: 0rem;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.section-overview {
    width: 100%;
    height: 420rem;
    background-repeat: no-repeat;
    background-image: url(../../Assets/Background/backgroundN1.svg);
    background-size: cover;
    padding: 0rem {top: 25rem};

    @include respond(big-desktop) {
        background-image: url(../../Assets/Background/backgroundN1-big.png);
        height: 400rem;}

    @include respond(tab-port) {
        display: none;
        background-image: url(../../Assets/Background/backgroundN1-small.svg);
        height: 480rem;
        transform: translateY(-10rem);
    }

    &__list {
        height: 87%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        @include respond(tab-port) {height: 70%;}
    }
}

.section-faq {
    margin-bottom: 18rem;

    @include respond(phone) {
        margin-bottom: 0rem;
    }
}